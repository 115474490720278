// Global mixin for debounce/delay
export default {
	data() {
		return {
			timeout: null
		};
	},
	methods: {
		debounceFn(fn, delay = 500) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(function() {
				fn();
			}, delay);
		}
	}
};
