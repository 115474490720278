<template>
	<el-input
			v-model="search"
			:placeholder="$t('global.pretraga')"
			@input="emitValue"
			clearable
			@clear="clear"
			ref="search">
		<i slot="prefix" class="el-input__icon el-icon-search"></i>
	</el-input>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
	props: {
		value: String,
		checkIfChanged: {
			type: Boolean,
			default: true
		}
	},
	name: 'search-box',
	data() {
		return {
			search: ''
		};
	},
	watch: {
		value(value) {
			this.search = value;
		}
	},
	methods: {
		clear() {
			this.search = '';
			this.emitValue('');
			this.$refs.search.focus();
		},
		emitValue: debounce(function(value) {
			if (!this.checkIfChanged || this.value !== value)
				this.$emit('input', value);
		}, 300)
	}
};
</script>
