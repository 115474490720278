import Vue from 'vue';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import Rollbar from 'vue-rollbar';

import router from './router';
import store from './store';

import App from './App';
import Auth from './services/auth';
import i18n from './translations';
import resources from './resources';
import apiGet from './api/get';
import apiSave from './api/save';
import apiUpdate from './api/update';
import apiDelete from './api/delete';
import apiPost from './api/post';
import apiPut from './api/put';
import utils from './utilities';
import debounceFn from './services/debounce';
import _ from 'lodash';
import XBrowserUpdate from 'x-browser-update';
import moment from 'moment';
import VueTheMask from 'vue-the-mask';
import VCharts from 'v-charts';
import ECharts from 'vue-echarts'; // refers to components/ECharts.vue in webpack
import VueOnlineProp from 'vue-online-prop';

// Element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/display.css';
import './styles/main.scss';
// .-------

// Font Awesome
import './assets/fontawesome/css/all.min.css';
// .-------


// Api
Vue.prototype.$api = resources;
Vue.prototype.$utils = function(name, ...args) {
	return utils[name].call(this, ...args);
};
Vue.prototype.$get = function(...args) {
	return apiGet.call(this, ...args);
};
Vue.prototype.$save = function(...args) {
	return apiSave.call(this, ...args);
};
Vue.prototype.$update = function(...args) {
	return apiUpdate.call(this, ...args);
};
Vue.prototype.$delete = function(...args) {
	return apiDelete.call(this, ...args);
};
Vue.prototype.$post = function(...args) {
	return apiPost.call(this, ...args);
};
Vue.prototype.$put = function(...args) {
	return apiPut.call(this, ...args);
};

Vue.prototype.moment = moment;
// .-------

// Setup Vue
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(Auth);
Vue.use(_);
Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value)
});
Vue.use(XBrowserUpdate);
Vue.use(VueTheMask);
Vue.use(VCharts);
Vue.use(Rollbar, {
	accessToken: 'eefa3b8463df4cd2968c42dd3b1702df',
	captureUncaught: true,
	captureUnhandledRejections: true,
	payload: {
		environment: process.env.NODE_ENV === 'production' ? 'production' : 'dev'
	}
});
Vue.use(VueOnlineProp);
// .-------

// Mixin
Vue.mixin(debounceFn);
// .-------


// Components
Vue.component('pagination', require('./components/pagination').default);
Vue.component('search-tool', require('./components/search-tool').default);
Vue.component('v-chart', ECharts);
// .-------


Vue.http.options.emulateJSON = true;

Vue.config.productionTip = false;

Vue.prototype.$hostname = process.env.VUE_APP_API_URL;
Vue.prototype.$openPdfUrl = process.env.VUE_APP_API_URL + '/pdf/';

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');
