export default function(path, params) {
	return new Promise((resolve, reject) => {
		this.$api[path].get(params).then((response) => {
			return response.json();
		}).then(resolve, (error) => {
			this.$utils('handleError', error);
			reject(error);
		});
	});
};
