import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

const prefix = process.env.VUE_APP_API_URL;

const resources = {
	login: prefix + '/login',
	drzave: prefix + '/drzave/{id}',
	korisnici: prefix + '/korisnici/{id}',
	korisniciMe: prefix + '/korisnici/me',
	korisniciPristup: prefix + '/korisnici/pristup',
	mesta: prefix + '/mesta/{id}',
	mestaZaLiniju: prefix + '/mesta/mesta_za_liniju/{id}',
	koefMestaLinije: prefix + '/mesta/koef_mesta_linije/{id}',
	koefLinijeMesta: prefix + '/mesta/koef_linije_mesta/{id}',
	linije: prefix + '/linije/{id}',
	linijeStatus: prefix + '/linije/status/{id}',
	linijeZaVezivanje: prefix + '/linije/linije_za_vezivanje/{id}/{idLinije}',
	cenovnik: prefix + '/cenovnik/{id}',
	cenovnikMnozilac: prefix + '/cenovnik/mnozilac',
	modeliObracuna: prefix + '/cenovnik/modeli_obracuna',
	modeliDoplate: prefix + '/cenovnik/modeli_doplate',
	ceneDoplate: prefix + '/cenovnik/doplate/{id}',
	odrediCenu: prefix + '/cenovnik/odredi_cenu',
	ceneDaniLista: prefix + '/cenovnik/cene_dani_lista/{idCenovnika}',
	ceneDani: prefix + '/cenovnik/cene_dani/{idCeneDana}/{pravac}',
	ceneDaniVirtuelno: prefix + '/cenovnik/dani_virtuelno/{idCenovnika}',
	cenePopuniCenu: prefix + '/cenovnik/popuni_cenu/{idCeneDana}/{cena}',
	ceneDaniStatus: prefix + '/cenovnik/cene_dani_status/{idCeneDana}',
	starosneGrupe: prefix + '/starosne_grupe/{id}',
	valute: prefix + '/valute/{id}',
	redVoznje: prefix + '/red_voznje/{id}',
	redVoznjeStatus: prefix + '/red_voznje/status/{id}',
	vezaneLinije: prefix + '/vezane_linije/{id}',
	vezaneLinijeStatus: prefix + '/vezane_linije/status/{id}',
	busStatus: prefix + '/bus_status/{id}',
	busStatusMesta: prefix + '/bus_status/mesta/{id}',
	busStatusStatus: prefix + '/bus_status/status/{id}',
	busStatusVremeRazlika: prefix + '/bus_status/vreme_razlika/{id}',
	agencije: prefix + '/agencije/{id}',
	agencijeStatus: prefix + '/agencije/status/{id}',
	kuponi: prefix + '/kuponi/{id}',
	kuponiGenerisanje: prefix + '/kuponi/multi',
	popusti: prefix + '/popusti/{id}',
	popustiStatus: prefix + '/popusti/status/{id}',
	popustiModeliPopusta: prefix + '/popusti/modeli_popusta',
	kursnaLista: prefix + '/kursna_lista/{id}',
	obavestenja: prefix + '/obavestenja/{id}',
	obavestenjaObjavi: prefix + '/obavestenja/objavi/{id}',
	obavestenjaStatus: prefix + '/obavestenja/status/{id}',
	mailchimp: prefix + '/obavestenja/mailchimp',
	// firebase: prefix + '/obavestenja/test',
	firebase: prefix + '/obavestenja/firebase',
	pristupi: prefix + '/pristupi/{id}',
	kontroleri: prefix + '/pristupi/kontroleri',
	grupeKorisnika: prefix + '/grupe_korisnika/{id}',
	karteDoplate: prefix + '/karte/doplacene_karte/{id}',
	karte: prefix + '/karte/{id}',
	karteValidacijaUplate: prefix + '/karte/validacija_uplate/{id}', // PUT Validacija uplate za kartu (promena statusa)
	karteStorniraj: prefix + '/karte/storniraj/{id}', // PUT Storniraj kartu (promena statusa)
	karteRefundiraj: prefix + '/karte/refundiraj/{id}', // PUT Refundiraj kartu (promena statusa)
	karteVozaciMail: prefix + '/karte/vozaci_mail', // GET Mailovi vozaca
	kartePromeniMailPlatioca: prefix + '/karte/promeni_mail_platioca', // PUT Promena maila platioca
	statistikaKursDanas: prefix + '/statistika/kurs_danas',
	statistikaUkupnoKarataStatus: prefix + '/statistika/ukupno_karata_status',
	statistikaHeatmapOpcije: prefix + '/statistika/heatmap_opcije',
	statistikaGodinaHeatmap: prefix + '/statistika/godina_heatmap/{godina}/{izSrbije}',
	statistikaDaniHeatmapOptions: prefix + '/statistika/dani_heatmap/{godina}/{idLinija}/{izSrbije}',
	statistikaHeatmapDownload: prefix + '/statistika/heatmap_download/{godina}/{idLinija}/{izSrbije}/{datum}',
	statistikaDashboardStatistika: prefix + '/statistika/dashboard_statistika',
	statistikaAktivnostKorisnika: prefix + '/statistika/aktivnost_korisnika',
	statistikaSearchLog: prefix + '/statistika/search_log/{id}',
	statistikaNacinProdaje: prefix + '/statistika/source_kupljene_karte/{godina}',
	statistikaEtapaKarata: prefix + '/statistika/etapa_karata/{godina}',
	statistikaProdateKarte: prefix + '/statistika/prodate_karte',
	statistikaInfoProdatih: prefix + '/statistika/info_prodatih/{datum}/{idLinije}',
	statistikaPosete: prefix + '/statistika/posete_raspon/{od}/{do}',
	openPDF: prefix + '/pdf/{token}',
	pdfEmail: prefix + '/pdf/email/{token}/{email}'
};

for (let key in resources)
	resources[key] = Vue.resource(resources[key]);

export default resources;
