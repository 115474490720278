<template>
	<el-pagination v-show="value.total" :key="key"
					:current-page.sync="value.page"
					:page-size="value.limit"
					:total="value.total"
					@current-change="changePage"
					layout="slot, prev, pager, next, jumper, ->"
					:background="true"
					align="center">
		<span class="el-pagination__jump">
			{{ $t('pagination.ukupnoPoStrani') }} &nbsp;
				<el-input-number v-model="value.limit" :controls="false" @change="changeLimit" :min="1" :max="100" class="el-input el-pagination__editor"/>
			/ {{value.total}}
		</span>
	</el-pagination>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
	name: 'PaginationComponent',
	props: {
		value: {
			required: true,
			type: Object,
			// eslint-disable-next-line vue/require-valid-default-prop
			default: {
				page: 1,
				limit: 10,
				total: 0
			}
		}
	},
	data() {
		return {
			key: 1
		};
	},
	watch: {
		value() {
			// rerender component by changing key
			this.key++;
		}
	},
	methods: {
		changePage(page) {
			this.value.page = page;
			this.update();
		},
		changeLimit: debounce(function(limit) {
			limit = parseInt(limit);
			limit = isNaN(limit) ? 1 : +limit;
			if (limit <= 0)
				limit = 1;

			this.value.limit = limit;
			this.update();
		}, 300),
		decreaseTotal(value) {
			this.value.total -= value;
			this.update();
		},
		calcPage() {
			let totalPages = Math.ceil(this.value.total / this.value.limit) || 1;
			if (this.value.page > totalPages)
				this.value.page = totalPages;
		},
		update() {
			this.calcPage();
			this.$emit('input', this.value);
		}
	}
};
</script>

<style>
	.el-pagination__editor.el-input {
		width: 45px;
	}
	.el-input-number.is-without-controls .el-input__inner {
		padding-left: 5px;
		padding-right: 5px;
	}
</style>
