import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

const routes = [
	{
		path: '/',
		name: 'root',
		component: () => import('./views/dashboard.vue')
	},
	{
		path: '/signin',
		name: 'signin',
		component: () => import('./views/signin.vue'),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('./views/dashboard.vue')
	},
	{
		path: '/statistika',
		name: 'statistika',
		component: () => import('./views/statistika.vue')
	},
	{
		path: '/statistika2',
		name: 'statistika2',
		component: () => import('./views/statistika2.vue')
	},
	{
		path: '/drzave',
		name: 'drzave',
		component: () => import('./views/drzave/list.vue')
	},
	{
		path: '/drzave/:id',
		name: 'drzave_edit',
		component: () => import('./views/drzave/edit.vue')
	},
	{
		path: '/korisnici',
		name: 'korisnici',
		component: () => import('./views/korisnici/list.vue')
	},
	{
		path: '/korisnici/:id',
		name: 'korisnici_edit',
		component: () => import('./views/korisnici/edit.vue')
	},
	{
		path: '/mesta',
		name: 'mesta',
		component: () => import('./views/mesta/list.vue')
	},
	{
		path: '/mesta/:id',
		name: 'mesta_edit',
		component: () => import('./views/mesta/edit.vue')
	},
	{
		path: '/mesta_koef',
		name: 'mesta_koef',
		component: () => import('./views/mesta_koef/list.vue')
	},
	{
		path: '/mesta_koef/:id',
		name: 'mesta_koef_edit',
		component: () => import('./views/mesta_koef/edit.vue')
	},
	{
		path: '/pristupi',
		name: 'pristupi',
		component: () => import('./views/pristupi/list.vue')
	},
	{
		path: '/pristupi/:id',
		name: 'pristupi_edit',
		component: () => import('./views/pristupi/edit.vue')
	},
	{
		path: '/linije',
		name: 'linije',
		component: () => import('./views/linije/list.vue')
	},
	{
		path: '/linije/:id',
		name: 'linije_edit',
		component: () => import('./views/linije/edit.vue')
	},
	{
		path: '/vezane-linije',
		name: 'vezane_linije',
		component: () => import('./views/vezane_linije/list.vue')
	},
	{
		path: '/vezane-linije/:id',
		name: 'vezane_linije_edit',
		component: () => import('./views/vezane_linije/edit.vue')
	},
	{
		path: '/cene',
		name: 'cene',
		component: () => import('./views/cene/list.vue')
	},
	{
		path: '/cene/:id',
		name: 'cene_edit',
		component: () => import('./views/cene/edit.vue')
	},
	{
		path: '/cene/dani/:id',
		name: 'dani',
		component: () => import('./views/cene/dani/list.vue')
	},
	{
		path: '/cene/cena/:idCena/dan/:id/datum/:datum/pravac/:pravac',
		name: 'dani_edit',
		component: () => import('./views/cene/dani/edit.vue')
	},
	{
		path: '/red-voznje',
		name: 'red_voznje',
		component: () => import('./views/red_voznje/list.vue')
	},
	{
		path: '/red-voznje/:id',
		name: 'red_voznje_edit',
		component: () => import('./views/red_voznje/edit.vue')
	},
	{
		path: '/starosne-grupe',
		name: 'starosne_grupe',
		component: () => import('./views/starosne_grupe/list.vue')
	},
	{
		path: '/starosne-grupe/:id',
		name: 'starosne_grupe_edit',
		component: () => import('./views/starosne_grupe/edit.vue')
	},
	{
		path: '/bus-status',
		name: 'bus_status',
		component: () => import('./views/bus_status/list.vue')
	},
	{
		path: '/bus-status/:id',
		name: 'bus_status_edit',
		component: () => import('./views/bus_status/edit.vue')
	},
	{
		path: '/agencije',
		name: 'agencije',
		component: () => import('./views/agencije/list.vue')
	},
	{
		path: '/agencije/:id',
		name: 'agencije_edit',
		component: () => import('./views/agencije/edit.vue')
	},
	{
		path: '/kuponi',
		name: 'kuponi',
		component: () => import('./views/kuponi/list.vue')
	},
	{
		path: '/kuponi/:id',
		name: 'kuponi_edit',
		component: () => import('./views/kuponi/edit.vue')
	},
	{
		path: '/popusti',
		name: 'popusti',
		component: () => import('./views/popusti/list.vue')
	},
	{
		path: '/doplate/:id',
		name: 'doplate_edit',
		component: () => import('./views/doplate/edit.vue')
	},
	{
		path: '/doplate',
		name: 'doplate',
		component: () => import('./views/doplate/list.vue')
	},
	{
		path: '/popusti/:id',
		name: 'popusti_edit',
		component: () => import('./views/popusti/edit.vue')
	},
	{
		path: '/grupe-korisnika',
		name: 'grupe_korisnika',
		component: () => import('./views/grupe_korisnika/list.vue')
	},
	{
		path: '/grupe-korisnika/:id',
		name: 'grupe_korisnika_edit',
		component: () => import('./views/grupe_korisnika/edit.vue')
	},
	{
		path: '/kursna-lista',
		name: 'kursna_lista',
		component: () => import('./views/kursna_lista/list.vue')
	},
	{
		path: '/obavestenja',
		name: 'obavestenja',
		component: () => import('./views/obavestenja/list.vue')
	},
	{
		path: '/obavestenja/:id',
		name: 'obavestenja_edit',
		component: () => import('./views/obavestenja/edit.vue')
	},
	{
		path: '/mailchimp',
		name: 'mailchimp',
		component: () => import('./views/mailchimp/list.vue')
	},
	{
		path: '/karte',
		name: 'karte',
		component: () => import('./views/karte/list.vue')
	},
	{
		path: '/karte-danas',
		name: 'karte-danas',
		component: () => import('./views/karte/list-dans.vue')
	},
	{
		path: '/karte-doplate',
		name: 'karte-doplate',
		component: () => import('./views/karte_doplate/list.vue')
	}
];

const router = new Router({
	mode: 'history',
	linkActiveClass: 'active',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition)
			return savedPosition;
		else {
			return {
				x: 0,
				y: 0
			};
		}
	},
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	store.commit('setLoading', true);
	let history = store.getters.session.history;
	if (history.length) {
		let lastPathInHistory = history[history.length - 1];

		// if user click on browser back button instead of header back button
		// remove last route from history
		if (lastPathInHistory === to.path)
			store.commit('removeRoute');

		// if user change route manually to go few steps back
		// remove all history after that route
		else if (history.includes(to.path)) {
			let index = history.indexOf(to.path);
			store.commit('setHistory', history.slice(0, index));
		}
		// user leave
		else if (from.path !== lastPathInHistory && from.path !== '/')
			store.commit('setHistory', []);
	}

	const auth = store.state.local.auth;
	if (to.meta.requiresAuth !== false && !auth.isLoggedIn)
		next('/signin');
	else
		next();
});

export default router;
