import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Prevodi od element-ui
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementFrLocale from 'element-ui/lib/locale/lang/fr'; // element-ui lang
import elementItLocale from 'element-ui/lib/locale/lang/it'; // element-ui lang
import elementDeLocale from 'element-ui/lib/locale/lang/de'; // element-ui lang
// Nas prevod za element-ui
import elementRsLocale from './lang-element/rs';
import store from '../store';

// Prevodi za APP
import rsLocale from './rs';
import enLocale from './en';
import deLocale from './de';
import itLocale from './it';
import frLocale from './fr';

const messages = {
	rs: {
		...rsLocale,
		...elementRsLocale
	},
	en: {
		...enLocale,
		...elementEnLocale
	},
	de: {
		...deLocale,
		...elementDeLocale
	},
	it: {
		...itLocale,
		...elementItLocale
	},
	fr: {
		...frLocale,
		...elementFrLocale
	}
};

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: store.getters.local.langs || 'rs', // set locale langs
	fallbackLocale: 'rs', // default jezik ako neki nema
	messages // set locale messages
});

export default i18n;
