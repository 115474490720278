<template>
	<div class="navbar header__main" :class="{ 'test-server': serverTest }">
		<div class="title">
			<h1>{{header.title}}</h1>
		</div>
		<div class="right-menu">
			<el-dropdown trigger="click" class="right-menu-item" @command="switchLocale">
				<!-- https://www.iconfinder.com/iconsets/195-flat-flag-psd-icons -->
				<el-button type="info">
					<img :src="getFlags(lengSelect)" v-bind:alt="lengSelect" style="width: 1em;"/>
				</el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item
							v-for="item in lang"
							:key="item.value"
							:disabled="lengSelect === item.value"
							:command="item.value">
						<img :src="getFlags(item.value)" v-bind:alt="item.value" style="width: 1em;"/>
						{{ item.label }}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<el-dropdown class="right-menu-item" trigger="click" @command="handleCommand">
				<el-button type="info" icon="fas fa-user-cog"> {{$store.getters.korisnikIme || ''}}</el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="logout">Odjava</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
export default {
	name: 'main-header',
	data() {
		return {
			lang: [{
				value: 'en',
				label: 'English'
			}, {
				value: 'de',
				label: 'Deutsch'
			}, {
				value: 'rs',
				label: 'Srpski'
			}, {
				value: 'it',
				label: 'Italiano'
			}, {
				value: 'fr',
				label: 'Français'
			}],
			serverTest: process.env.NODE_ENV !== 'production',
			lengSelect: this.$i18n.locale
		};
	},
	computed: {
		header() {
			return this.$store.getters.header;
		}
	},
	mounted() {
		console.log(process.env.NODE_ENV);
	},
	methods: {
		logout() {
			this.$store.commit('updateAuth', {});
			this.$router.push('/signin').catch(err => {
				console.log(err);
			});
			this.$message({
				showClose: true,
				type: 'info',
				message: this.$t('login.logoutSuccess'),
				duration: 2000
			});
		},
		switchLocale(val) {
			if (val !== this.$store.getters.langs) {
				this.$i18n.locale = val;
				this.$store.commit('langs', val);
			}
			else {
				this.lengSelect = this.$store.getters.langs;
				this.$i18n.locale = this.$store.getters.langs;
			}
			this.lengSelect = this.$i18n.locale;
		},
		handleCommand(command) {
			this[command]();
		},
		getFlags(leng) {
			let images = require.context('../assets/flags/', false, /\.png$/);
			return images('./' + leng + '.png');
		}
	}
};
</script>

<style>
	.test-server {
		background-color: rgba(255, 0, 0, 0.53) !important;
	}
</style>

