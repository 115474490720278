export default function(path, ...params) {
	return new Promise((resolve, reject) => {
		this.$api[path].update(...params).then((response) => {
			this.$utils('saveLoad');
			this.$utils('showResponse', response);
			this.$utils('goBack');
			resolve(response);
		}, (error) => {
			this.$utils('saveLoad');
			this.$utils('handleError', error);
			reject(error);
		});
	});
};
