export default function(path, id) {
	this.$confirm(this.$t('confirm.opisBrisanje'), this.$t('confirm.upozorenje'), {
		confirmButtonText: this.$t('confirm.obrisi'),
		cancelButtonText: this.$t('confirm.odustani'),
		type: 'warning'
	}).then(() => {
		return new Promise((resolve, reject) => {
			this.$api[path].delete({ id: id }).then((response) => {
				this.$utils('showResponse', response);
				this.$refs.pagination.decreaseTotal(1);
				resolve(response);
			}, (error) => {
				this.$utils('handleError', error);
				reject(error);
			});
		});
	}).catch(() => {
	});
};
