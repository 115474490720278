<template>
	<div :class="{'menu__open': !isCollapse, 'menu__close': isCollapse}">
		<div class="icon-collapse" @click="toggleCollapse">
			<div class="logo-img-btn">
				<i class="fas fa-angle-double-left"/>
				<img src="../assets/bogojevic.png" class="img-logo">
			</div>
		</div>
		<el-menu :default-active="$route.path"
					:collapse="isCollapse"
					@select="selectMenu"
					:collapse-transition="false"
					:class="{'el-menu--open': !isCollapse, 'el-menu--close': isCollapse}">

			<!-- first level -->
			<template v-for="(nav, i) in navigation">
				<!-- first level navigation without sub items-->
				<el-menu-item class="first-level" v-if="!nav.subItems" v-show="nav.show" :index="nav.path || ''" :key="i">
					<i v-if="nav.icon" :class="nav.icon"></i>
					<span slot="title">{{nav.label}}</span>
				</el-menu-item>

				<!-- first level navigation with sub items-->
				<el-submenu class="first-level" v-else v-show="findTrueShow(nav.subItems)" :index="'item-' + i" :key="'item-' + i">
					<template slot="title">
						<i v-if="nav.icon" :class="nav.icon"></i>
						<span slot="title">{{nav.label}}</span>
					</template>

					<!-- second level -->
					<template v-for="(sub, i2) in nav.subItems">
						<!-- second level navigation without sub items-->
						<el-menu-item v-if="!sub.subItems" v-show="sub.show" :index="sub.path || ''" :key="'sub-' + i2">
							<i v-if="sub.icon" :class="sub.icon"></i>
							<span slot="title">{{sub.label}}</span>
						</el-menu-item>

						<!-- second level navigation with sub items-->
						<el-submenu v-else v-show="findTrueShow(sub.subItems)" :key="'sub-item-' + i2" :index="'sub-item-' + i2">
							<template slot="title">
								<i v-if="sub.icon" :class="sub.icon"></i>
								<span slot="title">{{sub.label}}</span>
							</template>

							<!-- third level -->
							<template v-for="(subSub, i3) in sub.subItems">
								<!-- third level navigation without sub items-->
								<el-menu-item v-if="!subSub.subItems" v-show="subSub.show" :index="subSub.path || ''" :key="'subSub-' + i3">
									<i v-if="subSub.icon" :class="subSub.icon"></i>
									<span slot="title">{{subSub.label}}</span>
								</el-menu-item>
							</template>

						</el-submenu>

					</template>

				</el-submenu>

			</template>

		</el-menu>
	</div>
</template>

<script>
export default {
	name: 'navigation',
	computed: {
		isCollapse() {
			return this.$store.getters.collapsedMenu;
		}
	},
	data() {
		return {
			navigation: []
		};
	},
	mounted() {
		this.createNavigation();
	},
	watch: {
		'$store.getters.local'() {
			this.createNavigation();
		}
	},
	methods: {
		createNavigation() {
			this.navigation = [
				{
					label: this.$t('navigacija.dashboard'),
					icon: 'fas fa-tachometer-alt',
					path: '/',
					show: this.roleApp(true)
				},
				{
					label: this.$t('navigacija.karte'),
					icon: 'fas fa-ticket-alt',
					path: '/karte',
					show: this.roleApp('Karte')
				},
				{
					label: this.$t('navigacija.karteDanas'),
					icon: 'fas fa-ticket-alt',
					path: '/karte-danas',
					show: this.roleApp('Karte')
				},
				{
					label: this.$t('navigacija.karteDoplate'),
					icon: 'fas fa-calendar-minus',
					path: '/karte-doplate',
					show: this.roleApp('Karte')
				},
				{
					label: this.$t('navigacija.linije'),
					icon: 'fas fa-code-branch',
					path: '',
					subItems: [
						{
							label: this.$t('navigacija.segmenti'),
							icon: 'fas fa-code-branch',
							path: '/linije',
							show: this.roleApp('Linije')
						},
						{
							label: this.$t('navigacija.redVoznje'),
							icon: 'far fa-calendar-alt',
							path: '/red-voznje',
							show: this.roleApp('Red_voznje')
						},
						{
							label: this.$t('navigacija.linije'),
							icon: 'fas fa-code-branch',
							path: '/vezane-linije',
							show: this.roleApp('Vezane_linije')
						},
						{
							label: this.$t('navigacija.busStatus'),
							icon: 'fas fa-bus-alt',
							path: '/bus-status',
							show: this.roleApp('Bus_status')
						}
					]
				},
				{
					label: this.$t('navigacija.cene'),
					icon: 'fas fa-euro-sign',
					path: '',
					subItems: [
						{
							label: this.$t('navigacija.cenovnici'),
							icon: 'fas fa-euro-sign',
							path: '/cene',
							show: this.roleApp('Cenovnik')
						},
						{
							label: this.$t('navigacija.kuponi'),
							icon: 'fas fa-ticket-alt',
							path: '/kuponi',
							show: this.roleApp('Kuponi')
						},
						{
							label: this.$t('navigacija.popusti'),
							icon: 'fas fa-percentage',
							path: '/popusti',
							show: this.roleApp('Popusti')
						},
						{
							label: this.$t('navigacija.doplate'),
							icon: 'fas fa-hand-holding-usd',
							path: '/doplate',
							show: this.roleApp('Cenovnik')
						}
					]
				},
				{
					label: this.$t('navigacija.agencije'),
					icon: 'fas fa-building',
					path: '/agencije',
					show: this.roleApp('Agencije')
				},
				{
					label: this.$t('navigacija.obavestenja'),
					icon: 'fas fa-bell',
					path: '/obavestenja',
					show: this.roleApp('Obavestenja')
				},
				{
					label: this.$t('navigacija.mailchimp'),
					icon: 'fab fa-mailchimp',
					path: '/mailchimp',
					show: this.roleApp('Obavestenja')
				},
				{
					label: this.$t('navigacija.kursnaLista'),
					icon: 'fas fa-funnel-dollar',
					path: '/kursna-lista',
					show: this.roleApp('Kursna_lista')
				},
				{
					label: this.$t('navigacija.statistika'),
					icon: 'fas fa-tachometer-alt',
					path: '/statistika',
					show: this.roleApp('Karte')
				},
				{
					label: this.$t('navigacija.statistika2'),
					icon: 'fas fa-tachometer-alt',
					path: '/statistika2',
					show: this.roleApp('Karte')
				},
				{
					label: this.$t('navigacija.postavke'),
					icon: 'fas fa-sliders-h',
					path: '',
					subItems: [
						{
							label: this.$t('navigacija.drzave'),
							icon: 'fas fa-globe-americas',
							path: '/drzave',
							show: this.roleApp('Drzave')
						},
						{
							label: this.$t('navigacija.mesta'),
							icon: 'fas fa-map-marker-alt',
							path: '/mesta',
							show: this.roleApp('Mesta')
						},
						{
							label: this.$t('navigacija.mestaKoef'),
							icon: 'fas fa-map-marker-alt',
							path: '/mesta_koef',
							show: this.roleApp('Mesta')
						},
						{
							label: this.$t('navigacija.korisnici'),
							icon: 'fas fa-users',
							path: '/korisnici',
							show: this.roleApp('Korisnici')
						},
						{
							label: this.$t('navigacija.starosneGrupe'),
							icon: 'fas fa-user-tag',
							path: '/starosne-grupe',
							show: this.roleApp('Starosne_grupe')
						},
						{
							label: this.$t('navigacija.pristupi'),
							icon: 'fas fa-low-vision',
							path: '/pristupi',
							show: this.roleApp('Pristupi')
						},
						{
							label: this.$t('navigacija.grupeKorisnika'),
							icon: 'fas fa-users-cog',
							path: '/grupe-korisnika',
							show: this.roleApp('Grupe_korisnika')
						}
					]
				}
			];
		},
		toggleCollapse() {
			this.$store.commit('toggleMenuCollapse');
		},
		selectMenu(index) {
			// clear session storage
			this.$store.commit('clearSession');

			this.$router.push(index);
		},
		roleApp(val) {
			if (!val)
				return false;
			if (val === true)
				return true;

			if (this.$store.getters.local.user.roles && this.$store.getters.local.user.roles[val])
				return !!((val in this.$store.getters.local.user.roles) && this.$store.getters.local.user.roles[val].view);
			else
				return false;
		},
		findTrueShow(val) {
			if (val) {
				if (_.find(val, { show: true }))
					return true;
			}
			else
				return null;
		}
	}
};
</script>
