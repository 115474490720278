import { isNumeric } from 'echarts/src/util/number';

const utils = {
	/**
	 * convert sort object to string (api parameter)
	 * sort object must have prop and order properties
	 * @param {Object} sort
	 * @returns {string}
	 */
	sortToString(sort = null) {
		if (!sort)
			sort = this.sortBy;

		return sort.prop + (sort.order === 'descending' ? '.asc' : '.desc');
	},
	/**
	 * check if sort is changed and apply newSort
	 * rules:
	 *    table must have attribute ref with value table (ref="table")
	 *    default sort must be setted like this:
	 *        sort: {
	 * 			prop: 'example',
	 * 			order: 'ascending' or 'descending'
	 * 		}
	 * @param {Object} newSort
	 * @param {Function} callback execute after change
	 */
	changeSort(newSort, callback) {
		if (newSort.prop !== this.sortBy.prop || newSort.order !== this.sortBy.order) {
			if (newSort.prop)
				this.sortBy.prop = newSort.prop;

			this.sortBy.order = (newSort.order === null) ? 'ascending' : newSort.order;
			this.$refs.table.sort(this.sortBy.prop, this.sortBy.order);
			callback();
		}
	},
	/**
	 * get array of ids from selected rows
	 * @param {Array} rows array of selected rows (objects)
	 * @returns {Array}
	 */
	rowsIds(rows) {
		return rows.map(row => row._id);
	},
	/**
	 * default value for pagination
	 * @returns {Object}
	 */
	defaultPagination() {
		return {
			page: 1,
			limit: 10,
			total: 0
		};
	},
	/**
	 * set pagination data (from response, ...)
	 * @param pagination {Object}
	 */
	setPag(data) {
		this.pagination.page = data.pagination.page;
		this.pagination.limit = data.pagination.limit;
		this.pagination.total = data.pagination.total;
	},
	/**
	 * return parameter object for get request
	 * @returns {Object}
	 */
	getParams() {
		let params = {};

		if (this.pagination)
			Object.assign(params, this.pagination);

		if (this.sortBy)
			params.sort = utils.sortToString(this.sortBy);

		if (this.search)
			params.search = this.search;

		return params;
	},
	/**
	 * set pagination page to 1 and call function to get data
	 * @param fn {Function}
	 */
	search(fn) {
		this.pagination.page = 1;
		fn();
	},
	/**
	 * splice element from array
	 * @param array
	 * @param index
	 * @param number
	 */
	splice(array, index, number = 1) {
		array.splice(index, number);
	},
	/**
	 * auto collect, arrange and save data in store
	 * this function is trying to collect:
	 *        pagination,
	 *        sortBy,
	 *        search,
	 *        filter,
	 *        data
	 * from component data object
	 * @param {String} key location name where to store data
	 */
	autoSave(key = '') {
		// if key is not set use component name as key
		if (!key)
			key = this.$options.name;

		let local = {};
		let session = {};

		// auto check for this properties
		let pag = this.pagination;
		let sortBy = this.sortBy;
		let search = this.search;
		let filter = this.filter;
		let data = this.data;

		if (pag) {
			if (pag.limit)
				local.limit = pag.limit;

			if (pag.page)
				session.page = pag.page;
		}
		if (sortBy)
			local.sortBy = sortBy;
		if (search)
			session.search = search;
		if (filter)
			session.filter = filter;
		if (data)
			session.data = data;

		// save collected data
		this.$store.commit('saveLocal', { key, value: local });
		this.$store.commit('saveSession', { key, value: session });
	},
	/**
	 * auto collect and load data in vue component
	 * @param {String} key
	 */
	autoLoad(key = '') {
		// if key is not set use component name as key
		if (!key)
			key = this.$options.name;

		let local = this.$store.getters.local[key] || {};
		let session = this.$store.getters.session[key] || {};

		// ensure that pagination has all the necessary properties before proceeding
		this.pagination = Object.assign(utils.defaultPagination(), this.pagination);

		// auto check for this properties
		let pag = this.pagination;
		let sortBy = local.sortBy;
		let search = session.search;
		let filter = session.filter;
		let data = session.data;

		if (local.limit)
			pag.limit = local.limit;
		if (session.page)
			pag.page = session.page;
		if (sortBy)
			this.sortBy = sortBy;
		if (search)
			this.search = search;
		if (filter)
			this.filter = filter;
		if (data)
			this.data = data;
	},
	/**
	 * show message to user
	 * default message type is success
	 * @param {Object} message http://element.eleme.io/#/en-US/component/message
	 */
	showMessage(message) {
		if (!message.type)
			message.type = 'success';

		this.$message(message);
	},
	/**
	 * show message to user
	 * default message type is success
	 * @param {Object} message http://element.eleme.io/#/en-US/component/message
	 */
	showResponse(response) {
		if (response.status === 200) {
			this.$message({
				type: 'success',
				message: this.$t('global.uspesno')
			});
		}
		else {
			this.$message({
				type: 'success',
				message: response.body.message
			});
		}
	},
	/**
	 * browser history go back
	 * @param {Object} message show message
	 */
	goBack(message = null) {
		return this.$store.dispatch('goBack').then(route => {
			if (message)
				this.$utils('showMessage', message);

			this.$router.replace(route);
		});
	},
	/**
	 * save current route and go to next (path)
	 * @param path {String}
	 */
	goTo(path) {
		this.$store.commit('saveRoute', this.$route.path);
		this.$router.push(path);
	},
	/**
	 * handlig response errors
	 * @param {Object} error
	 */
	handleError(error) {
		if (!error || !error.bodyText)
			return;

		let errorMsg = error.body ? (error.body.message || '') : '';
		let errorDsc = error.body ? (error.body.description || '') : '';
		let message = (!error.bodyText) ? 'Server not responding.' : '';

		if (errorMsg)
			message = errorMsg + '.';

		if (errorDsc)
			message += ' ' + error.body.description;

		if (!message)
			message = error.status + ' ' + error.statusText;

		this.$utils('showMessage', {
			type: 'error',
			message
		});
	},
	/**
	 * Provera da li ima pristup
	 * @param kontroler
	 * @param akcija
	 * @returns {boolean}
	 */
	roleUser(kontroler, akcija) {
		if (!kontroler)
			return false;
		if (kontroler === true)
			return true;

		if (this.$store.getters.local.user.roles && this.$store.getters.local.user.roles[kontroler]) {
			if ((kontroler in this.$store.getters.local.user.roles) && this.$store.getters.local.user.roles[kontroler]) {
				if (this.$store.getters.local.user.roles[kontroler][akcija])
					return true;
				else
					return false;
			}
			else
				return false;
		}
	},
	/**
	 * set loading to false after all functions are finished
	 * @param array
	 * @returns {Promise<any[]>}
	 */
	stopLoadingAfter(array) {
		return Promise.all(array).then(result => {
			this.$nextTick(() => {
				this.$store.commit('setLoading', false);
			});
			return result;
		}).catch(() => {
			this.$nextTick(() => {
				this.$store.commit('setLoading', false);
			});
		});
	},
	/**
	 * Prevodi
	 * @param prevod
	 * @returns {*}
	 */
	vratiPrevod(prevod) {
		return this.$t(prevod);
	},
	/**
	 * moment date format function
	 * @param date [ new Date() ]
	 * @param fomat [ YYYY-MM-DD HH:mm:ss ]
	 * @returns {string}
	 */
	formatDate(date, fomat = 'DD.MM.YYYY') {
		if (!date || date === '0000-00-00 00:00:00' || date === '0000-00-00')
			return '-';
		return this.moment(date).format(fomat);
	},
	/**
	 * Foramt cene na 2 decimale
	 * @param value
	 * @param digits
	 * @returns {string}
	 */
	formatBroja(value, digits = 2) {
		if (parseInt(value) && isNumeric(value))
			return (value / 1).toFixed(digits).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		else
			return (0).toFixed(digits).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
	},
	/**
	 * Provera jednakosti nizova
	 * @param data1
	 * @param data2
	 * @returns {boolean}
	 */
	loIsEqual(data1, data2) {
		if (!data1 || !data2)
			return false;
		return _.isEqual(data1, data2);
	},
	/**
	 * Is Array provera
	 * @param arr
	 * @returns {*|arg is Array<any>|boolean}
	 */
	loIsArray(arr) {
		if (!arr)
			return false;
		return _.isArray(arr);
	},
	/**
	 *
	 * @param status
	 */
	saveLoad(status) {
		if (status)
			this.onSubmitLoading = true;
		else
			this.onSubmitLoading = !this.onSubmitLoading;
	},
	cacheUser() {
		return this.$get('korisniciMe').then(data => {
			const user = this.$store.state.local.user;
			user.first_name = data.first_name;
			user.last_name = data.last_name;
			user.roles = data.pristupi;
			user.grupe = data.grupe;
			this.$store.commit('updateUser', user);
		});
	}
};

export default utils;
