export const showMenu = state => state.showMenu;
export const collapsedMenu = state => state.local.collapsedMenu;
export const langs = state => state.local.langs;
export const loading = state => state.loading;
export const local = state => state.local;
export const session = state => state.session;
export const auth = state => state.local.auth;
export const korisnikGrupa = state => state.local.user.grupe;
export const korisnikIme = (state) => {
	if (state.local.user.first_name || state.local.user.last_name)
		return state.local.user.first_name + ' ' + state.local.user.last_name;
	else
		return false;
};


